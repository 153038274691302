<template>
    <button
        class="tw-relative tw-flex tw-h-16 tw-w-16 tw-items-center tw-justify-center tw-rounded-full tw-bg-[var(--color-primary)] tw-shadow-xl"
        type="button"
        @click="go()"
    >
        <channel-icon />
        <badge-status class="tw-absolute tw-right-3 tw-top-4" v-if="isAvailableConversation || isAvailableVideo" size="small" />
    </button>
</template>
<script setup lang="ts">
    import BadgeStatus from '@/components/live/BadgeStatus.vue';
    import ChannelIcon from '@/components/live/ChannelIcon.vue';
    import { useWidgetStore } from '@/store/widget';
    import { storeToRefs } from 'pinia';
    import { useRouter } from 'vue-router';

    const widgetStore = useWidgetStore();
    const { isAvailableConversation, isAvailableVideo, backRouteName } = storeToRefs(widgetStore);

    const router = useRouter();

    const go = () => {
        router.replace({ name: backRouteName.value ?? 'main' });
        widgetStore.updateBackRouteName(null);
    };
</script>
